<script>
export default {
  mounted() {
    this.$el.scrollIntoView({ behavior: 'smooth' });
    this.$store.dispatch('lego/getAllRecourse')
  }
}

</script>


<template>
  <div class="layout bg-layout">
    <RouterView />
  </div>
</template>

<style scoped>

.layout {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: var(--lego-dark);
  border-radius:15px;
}
.bg-layout {
  /* background-image: url(/img/blob-scene-haikei.svg); */
  background-image: url('/img/blob-scene-haikei-l.svg');

  background-size: cover;
  background-position: top;
}
.dark .bg-layout {
  /* background-image: url(/img/blob-scene-haikei.svg); */
  background-image: url('/img/blob-scene-haikei.svg');

  background-size: cover;
  background-position: top;
}

</style>